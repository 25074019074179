import { ocrGetAsync } from '~~/api/contentInfo/ocr/ocr';

export const isProductCode = async code => {
    const codeRaw = code.replaceAll(' ', '');

    if (codeRaw === '') {
        return;
    }

    const result = await ocrGetAsync(codeRaw);
    const failed = result.error?.code === -33000;

    if (failed) {
        return {
            status: 'error',
        };
    } else {
        return {
            status: 'success',
        };
    }
};
